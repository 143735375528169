<script>
import componentFactory from "./componentFactory.js";
let pdfjsWrapper = require("./pdfjsWrapper.js").default;
let PDFJS = require("pdfjs-dist/es5/build/pdf.js");
if (typeof window !== "undefined" && "Worker" in window && navigator.appVersion.indexOf("MSIE 10") === -1) {
  let PdfjsWorker = require("worker-loader!./pdf.worker.js");
  PDFJS.GlobalWorkerOptions.workerPort = new PdfjsWorker();
}
let component = componentFactory(pdfjsWrapper(PDFJS));
export default component;
</script>
